@import 'src/styles/variables/variables';

@include useTheme();

.Root {
  padding: 61px 18px 75px;

  @include min-width(large-tablet) {
    padding: 67px 40px 91px;
  }

  @include min-width(large-desktop) {
    padding: 67px 0 90px;
    max-width: 1290px;
    margin: 0 auto;
  }

  .Title {
    @include secondaryFont(400);
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.4px;
    margin-bottom: 33px;
    text-align: center;


    @include min-width(large-tablet) {
      font-size: 48px;
      line-height: 58px;
      margin-bottom: 46px;
    }
  }

  .Swiper {
    width: 100%;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 59px;
      height: 84px;
      background: linear-gradient(90deg, rgba(243, 240, 235, 0.00) 0%, #FFF 70.31%);
      z-index: 1;
    }

    @include min-width(large-tablet) {

      // Disable swiper on desktop
      :global .swiper-wrapper {
        transform: none !important;
        display: flex;
        flex-wrap: wrap !important;
        flex-flow: row;
        justify-content: center;
        gap: 7px;
      }

      :global .swiper-slide-duplicate {
        display: none;
      }

      &:after {
        display: none;
      }
    }
  }

  .Slider {
    width: auto;

    a {
      display: inline-flex;
      align-items: flex-start;
      padding: 9.611px 19.221px;
      border-radius: 30.754px;
      background: #E8E5DE;
      text-align: center;

      &:active,
      &:hover {
        background: $macadamia;
      }
    }

    @include min-width(large-tablet) {
      margin-right: 3px;
      margin-bottom: 15px;
    }
  }
}

.ButtonsSlider {
  flex-direction: column;
  height: 100px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  padding: 0 18px;
  -webkit-overflow-scrolling: touch;
  overflow-y: hidden;
  overscroll-behavior-x: contain;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    display: inline-block;
    vertical-align: top;
    margin: 0 7px 7px 0;
    padding: 9.611px 19.221px;
    height: 43px;
    border-radius: 30.754px;
    background: #E8E5DE;
    text-align: center;
    scroll-snap-align: center;
    white-space: nowrap;

    &:active,
    &:hover {
      background: $macadamia;
    }

    @include min-width(desktop) {
      margin: 0 15px 25px 0;
    }
  }

  @include min-width(desktop) {
    overflow: visible;
    height: auto;
    text-align: center;

  }
}

.ButtonsSliderWrapper {
  width: 1740px;

  @include min-width(desktop) {
    width: auto;
  }
}

.ButtonsFade {
  position: relative;
  margin: 0 -18px -7px;

  &::before, &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 20px;
    background: linear-gradient(to right, #fff, rgba(#fff, 0));
    z-index: 1;
  }

  &::after {
    left: auto;
    right: 0;
    background: linear-gradient(to left, #fff, rgba(#fff, 0));
  }
}
