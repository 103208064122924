@import 'src/styles/variables/variables';

@include useTheme(2025);

.Root {
  padding: 51px 27px 58px;
  color: $core2;
  background: $cinnamon;

  .Title {
    @include primaryFont(500);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .Content {
    @include primaryFont(300);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    text-align: center;
    max-width: 741px;
    margin: 0 auto;
  }
}
